@use "fonts";
@import "../../node_modules/flickity/dist/flickity.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    margin: 0 auto;
    padding: 0;
    font-family: "Graphik", sans-serif;
    font-weight: normal;
    box-sizing: border-box;
    height: 100vh;
    overscroll-behavior-x: none;
    overflow-x: hidden !important;
  }
}

@layer components {
  .home-banner-img {
    @apply h-full w-auto object-cover object-left max-h-[68vh] rounded-[3rem] sm:rounded-[5rem] lg:rounded-[10rem] xl:rounded-[13rem] lg:max-h-[70vh];
  }
  .home-banner-title {
    @apply flex items-center gap-2 mt-3 justify-center;
  }
  .price-img {
    @apply w-4/5 mx-auto md:w-[18vw] object-cover object-center;
  }
  .unit-img {
    @apply object-cover object-left w-4/5 md:w-[35vw] mx-auto;
  }

  /* Flickity For Home Mobile */
  .carousel {
    @apply w-[120%] mx-[-10%];
  }
  .carousel-cell {
    @apply w-[80%] h-[80vh] ml-4;
    counter-increment: gallery-cell;
  }
  .carousel-cell-img {
    @apply w-full h-[68vh] mx-auto object-cover object-center rounded-[3.5rem];
  }

  @media (min-width: 512px) {
    .carousel-cell {
      @apply w-auto min-w-[320px];
    }
  }

  /* Flickity For General Page */
  .carousel-all {
    @apply w-full;
  }
  .carousel-cell-all {
    @apply w-[80%] h-auto mr-3;
    counter-increment: carousel-cell;
  }
  .carousel-cell-img-all {
    @apply h-auto w-full object-cover object-center;
  }
}

.flickity-button {
  color: white !important;
  &:hover {
    background: rgba($color: #000000, $alpha: 0.7);
  }
}
